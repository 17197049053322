import { Box, Collapse, useDisclosure } from "@chakra-ui/react";
import React from "react";
import styles from "../../styles/components/selectAccordion.module.css";

function MenuAccordion({ label, expanded, index, onExpand, menuItem }) {
  return (
    <Box className={styles.main}>
      <Box onClick={onExpand} className={styles.box}>
        <Box className={styles.labelSelected}>{label}</Box>
        <img
          className={
            expanded === index ? styles.activeIcon : styles.inActiveIcon
          }
          src="/new-ui/images/up-icon.svg"
          alt="Up"
        />
      </Box>
      <Collapse in={expanded === index}>
        <Box className={styles.options}>
          {menuItem[0]?.menuItems?.map((option, index) => {
            return (
              <a href={option?.url}>
                <Box className={styles.option} key={index}>
                  <Box className={styles.label}>{option.title}</Box>
                </Box>
              </a>
            );
          })}
        </Box>
      </Collapse>
    </Box>
  );
}

export default MenuAccordion;
