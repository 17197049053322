import { Box, Fade } from "@chakra-ui/react";
import React from "react";
import styles from "../../styles/components/filterbox.module.css";
import MenuAccordion from "../atoms/MenuAccordion";

import Link from "next/link";
import { useSession } from "next-auth/client";

function SmallScreenMenu({ active, menuData }) {
  const [expanded, setExpanded] = React.useState(null);
  const ref = React.useRef(null);
  const [session, loadingSession] = useSession();

  return (
    <Box zIndex={active ? 2 : -1} position={"relative"} w="100%">
      <Fade in={active} ref={ref}>
        <Box className={styles.body}>
          {menuData.map((menuItem, index) => {
            if (menuItem?.menuSection.length > 0) {
              return (
                <MenuAccordion
                  key={index}
                  onExpand={() =>
                    setExpanded(index === expanded ? null : index)
                  }
                  expanded={expanded}
                  index={index}
                  label={menuItem.menuTitle}
                  menuItem={menuItem?.menuSection}
                />
              );
            } else {
              if (menuItem.menuTitle === "Welcome") {
                return (
                  <>
                    {session && (
                      <Link href={menuItem.href}>
                        <Box className={styles.option} key={index}>
                          <Box className={styles.labelSelected}>
                            {menuItem.menuTitle}
                          </Box>
                        </Box>
                      </Link>
                    )}
                  </>
                );
              } else {
                return (
                  <Link href={menuItem.href}>
                    <Box className={styles.option} key={index}>
                      <Box className={styles.labelSelected}>
                        {menuItem.menuTitle}
                      </Box>
                    </Box>
                  </Link>
                );
              }
            }
          })}
        </Box>
      </Fade>
    </Box>
  );
}

export default SmallScreenMenu;
