import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Box,
  DrawerBody,
  VStack,
  StackDivider,
} from "@chakra-ui/react";
import Link from "next/link";
import React from "react";
import styles from "../../styles/components/header.module.css";
import {menuData} from "./data";
import SmallScreenMenu from "./SmallScreenMenu";

export default function MyDrawer({ isOpen, setIsOpen }) {
  return (
    <>
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={() => setIsOpen(false)}
      >
        <DrawerOverlay />
        <DrawerContent pt="10px">
          <Box
            display={"flex"}
            alignItems="flex-start"
            justifyContent={"space-between"}
            w="100%"
            px="20px"
            mt='20px'
          >
            <DrawerCloseButton pt="5px" />
            <Link href={"/"}>
              <img
                src="/new-ui/images/small-logo.svg"
                alt="CoverClap"
                width={"171px"}
              />
            </Link>
          </Box>
          <DrawerBody>
            <VStack
              divider={<StackDivider borderColor="gray.200" />}
              spacing={4}
              align="stretch"
              mt={"40px"}
            >
              <Box className={styles.box}>
                <SmallScreenMenu active={true} menuData={menuData} />
              </Box>
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}
