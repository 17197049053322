import React, { useState } from "react";

import { Button, Box } from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";

import { useSession, signIn, signOut } from "next-auth/client";

import styles from "../../styles/components/auth.module.css";
function AuthModal({ authModal, setauthModal }) {
  return (
    <>
      {authModal && (
        <div className={styles.modalApp}>
          <div className={styles.modal}>
            <div className={styles.modal_content}>
              <span
                className={styles.modalclose}
                onClick={() => setauthModal(false)}
              >
                {" "}
                <CloseIcon color="#000000" w={15} h={15} />
              </span>
              <Box className={styles.LoginText}>Login</Box>
              <Box
                margin="auto"
                mt={["89px", "63px"]}
                width={["90%", "252px"]}
                height="54px"
                borderRadius={"full"}
              >
                <Button
                  className={styles.button}
                  bg={"black"}
                  color={"white"}
                  borderRadius={"88px"}
                  w="full"
                height="54px"

                  onClick={() => signIn("google")}
                >
                  <img
                    src="/new-ui/images/googleLogo.svg"
                    alt="Badge"
                    className={styles.googleLogoIcon}
                  />{" "}
                  Sign in with Google
                </Button>
              </Box>
              <Box
                margin="auto"
                width={["90%", "252px"]}
                height="54px"
                borderRadius={"full"}
                mb={["107px", "107px"]}
                mt={["20px", "20px"]}

              >
                <Button
                  className={styles.button}
                  bg={"black"}
                  color={"white"}
                  borderRadius={"88px"}
                  w="full"
                height="54px"

                  onClick={() => signIn("facebook")}
                >
                  <img
                    src="/new-ui/images/fbLogo.svg"
                    alt="Badge"
                    className={styles.googleLogoIcon}
                  />{" "}
                  <span className={styles.fbText}>Sign in with Facebook</span>
                </Button>
              </Box>

              <Box className={styles.modalFooter}>
              <Box className={styles.bottomLine} />

                <Box className={styles.authModalFooter}>
                  Click “Sign Up” to agree to CoverClap{" "}
                  <a href="/terms">
                    <span className={styles.termsOfService}>
                      Terms of Service
                    </span>{" "}
                  </a>
                  and acknowledge that CoverClap{" "}
                  <a href="/privacy">
                    <span className={styles.termsOfService}>Privacy Policy</span>{" "}
                  </a>
                  applies to you.
                </Box>
              </Box>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
export default AuthModal;
