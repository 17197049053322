import { Box } from "@chakra-ui/react";
import Link from "next/link";
import React from "react";
import styles from "../../styles/components/footer.module.css";

function Footer() {
  const icons = [
    {
      name: "instagram",
      icon: "/new-ui/images/insta.svg",
      link: "/",
    },
    {
      name: "facebook",
      icon: "/new-ui/images/facebook.svg",
      link: "/",
    },
    {
      name: "twitter",
      icon: "/new-ui/images/twitter.svg",
      link: "/",
    },
  ];
  return (
    <Box>
      <Box className={styles.footer}>
        <Box className={styles.left}>
          <Link href={"/"}>
            <img
              src="/new-ui/images/coverclap-logo.svg"
              alt="CoverClap"
              loading="lazy"
              width={'233px'}

            />
          </Link>
        </Box>
        <Box className={styles.mid}>
          <a href="/blog">
            <span className={styles.linkText}>Blog</span>
          </a>
          {/* <Link href="#">
            <span className={styles.linkText}>About</span>
          </Link> */}
          <a href="/braids/montreal">
            <span className={styles.linkText}>Braids</span>
          </a>
          <a href="/mens-haircut/montreal">
            <span className={styles.linkText}>Men's haircut</span>
          </a>
          <a href="/makeup/montreal">
            <span className={styles.linkText}>Makeup</span>
          </a>
          <a href="/korean-hair-salon/montreal">
            <span className={styles.linkText}>Korean Hair Salon</span>
          </a>
          <a href="/hair-salon/montreal">
            <span className={styles.linkText}>Hair Salons</span>
          </a>
          <a href="/black-hair-salons/montreal">
            <span className={styles.linkText}>Black Hair Salons</span>
          </a>
          {/* <a href="/hair-extensions/montreal">
            <span className={styles.linkText}>Hair Extensions</span>
          </a> */}
          <a href="/curly-hair-salons/montreal">
            <span className={styles.linkText}>Curly Hair Salons</span>
          </a>
          {/* <a href="/wigs/montreal">
            <span className={styles.linkText}>Wigs</span>
          </a>
          <a href="/kids-haircut/montreal">
            <span className={styles.linkText}>Kids Haircut</span>
          </a> */}
        </Box>
        <Box className={styles.right}>
          {icons.map((icon, index) => {
            return (
              <Link key={index} href={icon.link}>
                <Box className={styles.link}>
                  <img src={icon.icon} alt={icon.name} loading="lazy" />
                </Box>
              </Link>
            );
          })}
        </Box>
      </Box>

      {/* Small Screen Footer */}

      <Box className={styles.footerSmall}>
        <Box className={styles.logoBox}>
          <Link href={"/"}>
            <img src="/new-ui/images/small-logo.svg" alt="CoverClap" width={'171px'}/>
          </Link>
        </Box>
        <Box className={styles.btmBox}>
          <Box className={styles.linksBox}>
            <Link href={"/blog"}>
              <span className={styles.linkTextSmall}>Blog</span>
            </Link>
            <a href="/braids/montreal">
              <span className={styles.linkText}>Braids</span>
            </a>
            <a href="/mens-haircut/montreal">
              <span className={styles.linkText}>Men's haircut</span>
            </a>
            <a href="/makeup/montreal">
              <span className={styles.linkText}>Makeup</span>
            </a>
            <a href="/korean-hair-salon/montreal">
              <span className={styles.linkText}>Korean Hair Salon</span>
            </a>
            <a href="/hair-salon/montreal">
              <span className={styles.linkText}>Hair Salons</span>
            </a>
            <a href="/black-hair-salons/montreal">
              <span className={styles.linkText}>Black Hair Salons</span>
            </a>
            {/* <a href="/hair-extensions/montreal">
              <span className={styles.linkText}>Hair Extensions</span>
            </a> */}
            <a href="/curly-hair-salons/montreal">
              <span className={styles.linkText}>Curly Hair Salons</span>
            </a>
            {/* <a href="/wigs/montreal">
              <span className={styles.linkText}>Wigs</span>
            </a> */}
            {/* <a href="/kids-haircut/montreal">
              <span className={styles.linkText}>Kids Haircut</span>
            </a> */}
            {/* <Link href={"#"}>
              <span className={styles.linkTextSmall}>About</span>
            </Link> */}
          </Box>
          <Box className={styles.iconsBox}>
            {icons.map((icon, index) => {
              return (
                <Link key={index} href={icon.link}>
                  <Box className={styles.link}>
                    <img src={icon.icon} alt={icon.name} loading="lazy" />
                  </Box>
                </Link>
              );
            })}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Footer;
