const webmenuData = [
  {
    menuTitle: "Find A Beauty Artist",
    href: "",

    menuSection: [
      {
        menuItems: [
          {
            title: "Men's Haircut",
            subtitle:
              "Hairdressing and barbering services for every face shape.",
            url: "/mens-haircut/montreal",
          },
          {
            title: "Braids",
            subtitle: "Get comfortable braids without damaging your hair.",
            url: "/braids/montreal",
          },
          // {
          //   title: "Makeup",
          //   subtitle: "Get the perfect makeup look for any occasion.",
          //   url: "/makeup/montreal",
          // },
          {
            title: "Hair Extensions",
            subtitle:
              "Transform your hair with expertly installed and blended extensions.",
            url: "/hair-extensions/montreal",
          },
        ],
      },
      {
        menuItems: [
          {
            title: "Popular Categories",
            subtitle: [
              {
                title: "Korean Hair Salons",
                url: "/korean-hair-salon/montreal",
              },
              {
                title: "Curly Hair",
                url: "/curly-hair-salons/montreal",
              },
              {
                title: "Wigs",
                url: "/wigs/montreal",
              },
              {
                title: "Black Hair Salons",
                url: "/black-hair-salons/montreal",
              },
              // {
              //   title: "Kids Haircut",
              //   url: "/kids-haircut/montreal",
              // },
            ],
          },
        ],
      },
    ],
  },
  {
    menuTitle: "CoverClap For Business",
    href: "https://learn.coverclap.com",
    menuSection: []
  }
];
const menuData = [
  ...webmenuData,
  {
    menuTitle: "Welcome",
    menuSection: [],
    href: "/welcome",
  },
  {
    menuTitle: "Blog",
    menuSection: [],
    href: "/blog",
  },
  {
    menuTitle: "About",
    menuSection: [],
    href: "/about",
  },
];

export { menuData, webmenuData };
