import { Box } from "@chakra-ui/react";
import React from "react";
import styles from "../../styles/components/header.module.css";
import Link from "next/link";
import UiButton from "../atoms/UiButton";
import MyDrawer from "./Drawer";
import { useSession, signIn, signOut } from "next-auth/client";
import AuthModal from "./AuthModal";
import { webmenuData } from "./data";
import { useSelector } from "react-redux";

function Header({ showModal, setShowModal }) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [authModal, setauthModal] = React.useState(false);
  const [session, loadingSession] = useSession();
  const { artistData } = useSelector((state) => state.artistReducer);

  return (
    <Box>
      <Box className={styles.header}>
        <Box className={styles.leftbox}>
          <Link href={"/"}>
            <img
              src="/new-ui/images/coverclap-logo.svg"
              alt="CoverClap"
              loading="lazy"
              width={"233px"}
            />
          </Link>
          {webmenuData?.map((menuItem) => {
            return (
              <React.Fragment key={menuItem.menuTitle}>
                {menuItem.menuSection.length > 0 ? (
                  <Box className={styles.menuWrapper}>
                    <Link href={menuItem.href}>
                      <span className={styles.megamenulinkText}>
                        {menuItem.menuTitle}
                      </span>
                    </Link>
                    <Box className={styles.megeMenu}>
                      {menuItem.menuSection.map((menuSection) => {
                        return (
                          <Box className={styles.innerBox}>
                            {menuSection.menuItems.map((item) => {
                              return (
                                <a href={item?.url || ""}>
                                  <Box className={styles.leftInnerBox}>
                                    <h3 className={styles.megemenyItemTitle}>
                                      {item.title}
                                    </h3>
                                    {typeof item?.subtitle === "string" ? (
                                      <p className={styles.megaMenuSubtitle}>
                                        {item.subtitle}
                                      </p>
                                    ) : (
                                      item?.subtitle?.map((subtitle) => {
                                        return (
                                          <a href={subtitle?.url || ""}>
                                            <p
                                              className={
                                                styles.megaMenuSubtitleList
                                              }
                                            >
                                              {subtitle?.title}
                                            </p>
                                          </a>
                                        );
                                      })
                                    )}
                                  </Box>
                                </a>
                              );
                            })}
                          </Box>
                        );
                      })}
                    </Box>
                  </Box>
                ) : (
                  <Link href={menuItem.href}>
                    <span className={styles.megamenulinkText}>
                      {menuItem.menuTitle}
                    </span>
                  </Link>
                )}
              </React.Fragment>
            );
          })}
        </Box>
        <Box className={styles.right}>
          {session && artistData && (
            <Box>
              <Link href="/welcome">
                <img
                  className={styles.toggle}
                  src="/new-ui/images/welcomeBurger.svg"
                  alt="Toggle"
                />
              </Link>
            </Box>
          )}

          <Box>
            <Link href="/blog">
              <span className={styles.linkText}>Blog</span>
            </Link>
          </Box>
          <Box>
            <Link href="/about">
              <span className={styles.linkText}>About</span>
            </Link>
          </Box>
          <Box
            h="50px"
            w="120px"
            onClick={() => {
              if (session) {
                signOut();
              } else {
                setauthModal(true);
              }
            }}
          >
            <UiButton hFull label={session ? "Logout" : "Login"} />
          </Box>
        </Box>
      </Box>
      {/* Small Screen Header */}
      <Box className={styles.headerSmall}>
        <MyDrawer setIsOpen={setIsOpen} isOpen={isOpen} />

        <Box className={styles.headerContainer}>
          <Box onClick={() => setIsOpen(!isOpen)}>
            <img
              className={styles.toggle}
              src="/new-ui/images/toggle.svg"
              alt="Toggle"
            />
          </Box>
          <Box>
            <Link href={"/"}>
              <img
                src="/new-ui/images/small-logo.svg"
                alt="CoverClap"
                width={"171px"}
              />
            </Link>
          </Box>
          <Box
            onClick={() => {
              if (session) {
                signOut();
              } else {
                setauthModal(true);
              }
            }}
          >
            <span className={styles.linkTextSmall}>
              {session ? "Logout" : "Login"}
            </span>
          </Box>
        </Box>
      </Box>
      <AuthModal
        authModal={showModal || authModal}
        setauthModal={showModal ? setShowModal : setauthModal}
      />
    </Box>
  );
}

export default Header;
