import { Button } from "@chakra-ui/react";
import React from "react";
import styles from "../../styles/components/button.module.css";
function UiButton({ bgColor, hFull, label, onClick, radius, color, ...rest }) {
  return (
    <Button
      className={styles.button}
      bg={bgColor || "#000000"}
      color={color || "white"}
      h={hFull && '100%'}
      _hover={{ bg: bgColor || "#000000" }}
      borderRadius={radius || "88px"}
      w="full"
      {...rest}
      onClick={onClick}
    >
      {label || "Button"}
    </Button>
  );
}

export default UiButton;
